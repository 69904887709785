
.text-top-signin h2 {
  text-align: center;
  color: #312e81;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 3rem;
  line-height: 5px;
}
.text-top-signin p {
  text-align: center;
  font-weight: 600;
}

.signin_input_css {
  width: 30%;
  color: #212529;
  margin-bottom: 16px;
}

.ant-input:focus {
  color: black;
}
.ant-input::placeholder {
  color: #808080;
}

.signin_submit_btn_css {
  background-color: var(--Brand-500, #6366f1);
  width: 495px;
  height: 50px;
  border-radius: 40px;
}


.signin-form-css {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signin-main-css {
  padding-top: 100px;
  height: 73vh;
  background-color: #e2e8f0;
}

.linktextsignin {
  display: flex;
  justify-content: center;
  color: black;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .linktextsignin {
    font-size: 12px;
  }
}

.password-eye-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.password_input .css-hagjoj-MuiInputBase-root-MuiOutlinedInput-root {
  height: 56px;
}

.title {
    text-align: center;
    color: #312e81;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 3rem;
}


@media screen and (max-width: 600px) {
  .signin_input_css{
    width: 80%
  }
}

@media  (min-width: 601px) and (max-width: 900px) {
  .signin_input_css{
    width: 50%
  }
}
