.superAdminMainCardDivStyle {
  width: 100%;
  height: 100vh !important;
  min-height: 752px !important;
  border-radius: 15px;
  padding: 15px 10px 0 0;
}
.superAdminSecondMainDiv {
  width: 100% !important;
  height: 100%;
  border-radius: 15px;
  background: var(--Neutral-200, #e2e8f0);
}
.addOrganizationAdminSecondDiv {
  width: 98%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 10.457px;
  background: var(--White, #fff);
  box-shadow: 0px 2.789px 6.972px 3.486px rgba(0, 0, 0, 0.09);
  padding: 12px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.superAdminCardStyle {
  width: 100%;
  height: 100% !important;
}
.superAdminMiddleParentDiv {
  width: 96%;
  height: 94%;
  border-radius: 15px;
  background: var(--Neutral-50, #f8fafc);
  /* padding: 20px; */
}
.superAdminProfileName {
  color: var(--Brand-900, #312e81);
  font-family: Into, "sans-serif";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.Adddoc_input_css {
  width: 495px !important;
  /* height: 50px;
    border-radius: 40px;
    border: 1px solid var(--Neutral-400, #94a3b8);
    background: transparent !important; */
  color: #212529;
}

@media screen and (max-width: 767px) {
  .Adddoc_input_css {
    width: 230px !important;
  }
}

.buttonContainer {
  padding-top: 20px;
  display: flex;
  gap: 10px;
}

.addButtonStyle {
  color: rgb(255, 255, 255);
  background-color: var(--Brand-500, #6366f1);
  padding: 15px;

  border-radius: 50px;
  margin-bottom: 10px;
}

.cancelButton {
  color: black;
  background-color: rgb(255, 255, 255);
  height: 50px;
  border-radius: 28px;
  width: 100px;
}
.ant-modal-footer {
  display: none;
}
