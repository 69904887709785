@media print {
    @page {
      margin: 0.25in;
    }
    body {
      margin: 0;
      padding: 0;
    }
    .MuiPaper-root {
      margin: 0;
    }
  }
  