.hoverDiv {
    transition: background-color 0.3s ease;
    border-radius: 10px;
    margin-right: 0.5em;
}

.hoverDiv:hover {
    background-color: rgb(205, 205, 205);
    border-radius: 10px;
    filter: brightness(90%);
}