.search_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: beige;
}

.mobile_view_Table {
  display: none;
}

.name_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
