.submit_btn_css {
    background-color: var(--Brand-500, #6366f1);
    width: 30%;
    height: 50px;
  }

  @media screen and (max-width: 600px) {
    .submit_btn_css{
      width: 80%
    }
  }

  @media  (min-width: 601px) and (max-width: 900px) {
    .submit_btn_css{
      width: 50%
    }
  }