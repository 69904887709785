.rows {
    display: flex;
    flex-direction: row;
    margin: 24px;
}

.space_alignment {
    width: 3%
}

.description {
    color: rgb(100, 98, 98);
    font-weight: bold;
    width:15%
}

.rejected_text{
    color: rgb(100, 98, 98);
    font-weight: bold;
}

.rejected_container {
    display: flex;
    flex-direction: row;
    width:15%
}