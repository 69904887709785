.form-title {
  font-weight: bold;
}

.input_field {
  width: 600px;
  height: 40px;
  color: #212529;
  margin-bottom: 16px;
}

.field_container {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.field_align {
  width: 5%;
  padding-top: 8px;
}

.btn_alignment {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* justify-content: space-between; */
  /* margin-bottom: 150px; */
}

.form_container {
  height: 63vh;
  overflow: auto;
}

.cons_in_charge_container {
  display: flex;
  flex-direction: column;
}

.add_btn_cons {
  width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.add_btn_uplodeIcons {
  /* width: 350px; */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.added_container {
  display: flex;
  flex-direction: row;
}
.scroll {
  margin-left: 5%;
  padding: 1%;
  overflow-y: scroll;
}

.scroll-container::-webkit-scrollbar {
  width: 12px;
  display: flex;
}

.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cons_field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width:480px;
  height: 35px;
  margin-bottom: 16px;
}
