.layout_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; 
}

.header_container {
    padding: 10px;
}


.college_logo {
    height:5rem;
}


.footerMain {
    background-color: #0f172a;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: column;
    height: 40vh;
    justify-content: center;
  }
  
  
  .footer_p_tag {
    color: #fff;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    margin: 0 !important;
  }
  .address_card {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0;
  }
  .footer_p_tag_2 {
    font-family: var(--text-base-font-family, "Lato-SemiBold", sans-serif);
    font-size: var(--text-base-font-size, 14px);
    line-height: var(--text-base-line-height, 24px);
    font-weight: var(--text-base-font-weight, 400);
    margin: 10px 0 !important;
    color: #fff;
  }
  .footerFIrstCOntent {
    padding: 50px;
    display: flex;
    flex-direction: column;
    width: 20%;
  }
  .footerAddress {
    width: 100%;
  }
  .firstHalf {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .footerFIrstCOntent p {
    margin-bottom: 10px;
  }
  
  .footerFIrstCOntent img {
    margin-right: 10px;
  }
  .footerEmail {
    display: block;
  }
  .footer_social_links {
    display: flex;
    gap: 10px;
  }
  
  .footer_social_links img {
    width: 30px;
    height: 30px;
  }
  .centeredText {
    text-align: center;
    padding: 0px 8px 0px 8px;
  }
/* /////////////// */


  .child_container {
    height: 75vh;
    margin-top: 30px;
  }


  @media screen and (max-width: 600px) {
    .header_container{
      display: flex;
      justify-content: center;
    }

    
}
  
@media (max-width: 767px) {
  .footerFIrstCOntent {
      width: auto;
      padding: 20px 10px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .footerFIrstCOntent {
      width: 30%;
  }
}

@media screen and (max-width: 768px) {
  .footerMain {
      padding: 15px 0;
      height: 60vh;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
  }
}

@media screen and (max-width: 1025px) {
  .footerMain {
      padding: 15px 0;
      min-height: 60vh;
  }
}


